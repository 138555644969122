<template>
  <button
    class="r-button"
    :class="classList"
    :disabled="disabled"
    @click="click"
  >
    <span
      v-if="isFilterActive && iconOnly"
      class="r-button__badge"
    />
    <div
      v-if="counter || counter === 0"
      class="r-button__counter"
    >
      {{ counter }}
    </div>
    <r-icon
      v-if="icon"
      class="r-button__icon"
      :loading="loading"
      :name="getIconName()"
      :color="getIconColor()"
      :rotate="getIconRotate()"
      :color-type="getIconColorType()"
      :size="tinyIcon ? 14 : mini || link ? 18 : 22"
    />
    <span
      v-if="!noSlot"
      class="r-button__text"
      :class="{ rtl }"
      :style="getColorType()"
    >
      <slot />
    </span>
  </button>
</template>

<script>
const types = [
  'default',
  'secondary',
  'danger',
  'warning',
  'primary',
  'success',
  ''
]
const colorTypes = ['primary', 'subhead', 'secondary', 'teritary', '']

export default {
  props: {
    type: {
      type: String,
      validator: type => types.includes(type.toLowerCase()),
      default: 'default'
    },
    colorType: {
      type: String,
      validator: type => colorTypes.includes(type.toLowerCase()),
      default: ''
    },
    mini: {
      type: Boolean,
      default: false
    },
    rtl: {
      type: Boolean,
      default: false
    },
    simple: {
      type: Boolean,
      default: false
    },
    noSlot: {
      type: Boolean,
      default: false
    },
    tinyIcon: {
      type: Boolean,
      default: false
    },
    uppercase: {
      type: Boolean,
      default: false
    },
    dropdown: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    icon: {
      type: [String, Object],
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    },
    stretch: {
      type: Boolean,
      default: false
    },
    bold: {
      type: Boolean,
      default: false
    },
    bolder: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    tiny: {
      type: Boolean,
      default: false
    },
    counter: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      hasSlot: false
    }
  },
  computed: {
    isFilterActive() {
      return (
        this.type === 'secondary' &&
        this.icon &&
        this.getIconName() === 'filter' &&
        this.active
      )
    },
    iconOnly() {
      return !this.hasSlot || this.noSlot
    },
    classList() {
      const props = [
        'iconOnly',
        'uppercase',
        'noPadding',
        'dropdown',
        'disabled',
        'stretch',
        'counter',
        'rounded',
        'loading',
        'active',
        'simple',
        'bolder',
        'link',
        'mini',
        'tiny',
        'bold',
        'rtl'
      ]
      const css = {}
      props.forEach(p => {
        css[p] = this[p] || this[p] === 0
      })

      css[this.type] = true

      return css
    }
  },
  created() {
    this.hasSlot = !!this.$slots.default?.[0]
  },
  beforeUpdate() {
    this.hasSlot = !!this.$slots.default?.[0]
  },
  methods: {
    getColorType() {
      if (!this.colorType) return ''
      else return `color: var(--text-${this.colorType})`
    },
    getIconName() {
      return typeof this.icon === 'string' ? this.icon : this.icon?.name || ''
    },
    getIconColor() {
      return typeof this.icon === 'string' ? '' : this.icon?.color || ''
    },
    getIconRotate() {
      return typeof this.icon === 'string' ? 0 : this.icon?.rotate || 0
    },
    getIconColorType() {
      return typeof this.icon === 'string' ? '' : this.icon?.type || ''
    },
    click(e) {
      e.target.blur()

      if (!this.disabled && !this.loading) {
        this.$emit('click', e)
      }
    }
  }
}
</script>

<style lang="scss">
.r-button {
  display: flex;
  gap: 0.33rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  border: none;
  padding: 0.25rem 0.5rem;
  height: 2.25rem;
  cursor: pointer;
  transition: ease 0.15s;
  overflow: hidden;
  max-width: 100%;
  position: relative;
  filter: brightness(1);

  &.rtl {
    direction: rtl;
  }

  &__badge {
    width: 8px;
    height: 8px;
    position: absolute;
    background-color: $accent-primary;
    border-radius: 50%;
    right: 4px;
    z-index: 10;
    top: 7px;
  }

  &.noPadding {
    padding: 0.25rem 0 !important;
  }

  &__icon {
    flex-shrink: 0;

    & + .r-button__text:not(.rtl) {
      padding-right: 0.5rem;
    }
  }

  &:not(.dropdown) {
    .r-button__icon {
      & + .r-button__text:not(.rtl) {
        padding-right: 0.25rem;
      }
    }
  }

  &__text {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    color: $text-subhead;
    transition: ease 0.15s;
    user-select: none;
    pointer-events: none;

    &:empty {
      display: none;
    }
  }

  .r-icon__svg {
    fill: $white;
  }

  // Default

  &.default {
    background-color: $button-secondary-bg;

    .r-icon__svg {
      fill: $icons-high-contrast;
    }

    &:hover:not(.disabled):not(.loading):not(.simple) {
      background-color: $button-secondary-bg-hover;

      .r-icon__svg {
        fill: $icons-high-contrast !important;
      }
    }
  }

  // Colors

  &.danger {
    background-color: $accent-danger;

    .r-button__text {
      color: $white;
    }

    .r-icon__svg {
      fill: $white !important;
    }

    &.simple {
      .r-icon__svg {
        fill: $accent-danger !important;
      }

      &:hover:not(.disabled):not(.loading) {
        .r-icon__svg {
          fill: $icons-high-contrast !important;
        }
      }
    }
  }

  &.primary {
    background-color: $button-primary-bg;

    .r-button__text {
      color: $white;
    }

    &.dropdown {
      &::after {
        background-color: $button-primary-bg !important;
      }
    }

    &.simple {
      .r-icon__svg {
        fill: $accent-primary !important;
      }

      &:hover:not(.disabled):not(.loading) {
        .r-icon__svg {
          fill: $icons-high-contrast !important;
        }
      }
    }
  }

  &.success {
    background-color: $accent-success;

    .r-button__text {
      color: $white;
    }

    &.simple {
      .r-icon__svg {
        fill: $accent-success !important;
      }

      &:hover:not(.disabled):not(.loading) {
        .r-icon__svg {
          fill: $icons-high-contrast !important;
        }
      }
    }
  }

  &.warning {
    background-color: $accent-warning;

    .r-button__text {
      color: $white;
    }

    &.simple {
      .r-icon__svg {
        fill: $accent-warning !important;
      }

      &:hover:not(.disabled):not(.loading) {
        .r-icon__svg {
          fill: $icons-high-contrast !important;
        }
      }
    }
  }

  &.secondary {
    background-color: $button-secondary-bg;

    &:hover:not(.disabled):not(.simple):not(.loading) {
      background-color: $button-secondary-bg-hover;

      .r-button__text {
        color: $accent-primary;
      }

      .r-icon__svg {
        fill: $icons-high-contrast !important;
      }
    }

    .r-icon__svg {
      fill: $icons-high-contrast;
    }

    &.active {
      background-color: $accent-primary-1;

      &.dropdown {
        &::after {
          background-color: $button-primary-bg;
        }
      }

      .r-button__text {
        color: $button-primary-bg;
      }

      .r-icon__svg {
        fill: $button-primary-bg;
      }

      &:hover:not(.disabled):not(.loading) {
        background-color: $accent-primary-1;

        .r-button__text {
          color: $text-subhead !important;
        }

        .r-icon__svg {
          fill: $text-subhead !important;
        }
      }
    }
  }

  // All hover

  &:hover:not(.disabled):not(.simple):not(.loading) {
    filter: brightness(0.85);
  }

  // Forms + states

  &.mini {
    max-height: 1.75rem;
    padding: 0.25rem 0.75rem;

    &.iconOnly {
      max-width: 1.75rem;
      padding: 0;
    }
  }

  &:hover:not(.disabled) {
    .r-button__icon {
      .r-icon__svg {
        transform: scale(1.1);
      }
    }
  }

  &.bold {
    .r-button__text {
      font-weight: 500;
    }
  }

  &.bolder {
    .r-button__text {
      font-weight: 600;
    }
  }

  &.uppercase {
    .r-button__text {
      font-size: 12px;
      line-height: 1.5;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  &.dropdown {
    grid-gap: 0.25rem;

    &.iconOnly {
      max-width: 4rem;
      grid-gap: 0rem;
      padding: 0.25rem 0.33rem 0.25rem 0.5rem;
    }

    &:after {
      content: '';
      margin-left: 4px;
      width: 8px;
      height: 4px;
      clip-path: polygon(0 0, 50% 100%, 100% 0);
      background-color: $icons-high-contrast;
    }
  }

  &.stretch {
    width: 100% !important;
  }

  &.rounded {
    border-radius: 50%;
  }

  &.link {
    display: inline-flex;
    padding: 0 0.25rem !important;
    height: 1.5rem !important;

    .r-button__text {
      margin-top: 0.1rem;
      border-bottom: 0.1rem dashed $dividers-low-contrast;
    }
  }

  &.simple {
    background-color: transparent;
    // padding: 0;
    width: auto;
    height: 28px;

    &:not(.iconOnly) .r-button__icon {
      padding-left: 0.25rem;
    }

    &:hover:not(.disabled):not(.loading) {
      .r-button__text {
        color: $button-primary-bg !important;
      }

      .r-icon__svg {
        fill: $button-primary-bg;
      }

      &.danger,
      &.warning,
      &.success {
        .r-button__text {
          color: $text-subhead !important;
        }

        .r-icon__svg {
          fill: $text-subhead;
        }
      }
    }

    &.active {
      .r-button__text {
        color: $button-primary-bg;
      }

      .r-icon__svg {
        fill: $button-primary-bg !important;
      }

      background-color: $accent-primary-1;
    }

    &.danger {
      .r-button__text {
        color: $accent-danger !important;
      }
    }

    &.warning {
      .r-button__text {
        color: $accent-warning !important;
      }
    }

    &.success {
      .r-button__text {
        color: $accent-success !important;

        &:hover:not(.disabled):not(.loading) {
          .r-button__text {
            color: $text-subhead !important;
          }
        }
      }
    }
  }

  &.iconOnly:not(.dropdown) {
    &.simple {
      &:hover:not(.disabled) {
        background-color: $accent-primary-1;
      }
    }
  }

  &.iconOnly:not(.dropdown):not(.counter) {
    padding: 0;
    width: 2.25rem;
    height: 2.25rem;
  }

  &.counter {
    height: 2.25rem;

    .r-button__counter {
      font-size: 12px;
      font-weight: 600;
      color: var(--text-primary);
    }
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &.loading {
    opacity: 0.8;
    filter: hue-rotate(-5deg) brightness(0.9);
    cursor: progress;
  }

  &.tiny {
    .r-button__text {
      font-size: 12px;
    }
  }
}
</style>
